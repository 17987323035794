$(document).ready(function () {
	
	// set the capabilities for the requests
	Capabilities = new Capabilities({
		getScope: function () {
			var params = $.deparam.querystring();
			if (params['scope']) {
				return Scope.External.value;
			}
			return Scope.Internal.value;
		}
	});

	window.addEventListener('click', function(e) {
		// Only run for iOS full screen apps
		if (('standalone' in window.navigator) && window.navigator.standalone) {
			if (e.target.tagName.toLowerCase() !== 'a' || e.target.hostname !== window.location.hostname){
				return;
			}
			// prevent link from opening in new tab
			e.preventDefault();
			window.location = e.target.href;
		}
    }, false);


});

